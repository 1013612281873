@import "styles/theme/_variables.scss";

.container {
  margin: 0 auto;
  text-align: center;

  &Simple {
    display: flex;
    align-items: center;
    margin: unset;
    text-align: left;

    & > .icon {
      margin: 0;
    }
  }
}

.icon {
  margin: 0 auto $spacer * 3;
  user-select: none;
  block-size: 42px;
  aspect-ratio: 1;
}

.message {
  &Simple {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin-left: $spacer * 6;

    & > .title {
      margin: 0 0 $spacer * 3;
      padding: 0;
    }
  }
}

.title {
  margin: 0 0 6px;
  padding: 2.5 * $spacer 0;
}

.description {
  margin: 0;

  p {
    margin: 0;
  }
}
